const _t = {
    HEX_STRING: 'hex-string',
    RGB_STRING: 'rgb-string',
    RGBA_STRING: 'rgba-string',
    HSL_STRING: 'hsl-string',
    HSLA_STRING: 'hsla-string',
    RGB_OBJECT: 'rgb-object',
    RGBA_OBJECT: 'rgba-object',
    VEC3_STRING: 'vec3-string',
    VEC4_STRING: 'vec4-string',
    VEC3_ARRAY: 'vec3-array',
    VEC4_ARRAY: 'vec4-array',
    THREE: 'three',
    CSS_COLOR: 'css-color',
};
function Ft(t) {
    if (t.length < 7) {
        const o = t[1],
            n = t[2],
            e = t[3];
        if (t.length > 4) {
            const a = t[4];
            t = `#${o}${o}${n}${n}${e}${e}${a}${a}`;
        } else t = `#${o}${o}${n}${n}${e}${e}`;
    }
    return [
        parseInt(t.substr(1, 2), 16) / 255,
        parseInt(t.substr(3, 2), 16) / 255,
        parseInt(t.substr(5, 2), 16) / 255,
        t.length > 7 ? parseInt(t.substr(7, 2), 16) / 255 : 1,
    ];
}
function te(t = []) {
    const [o = 0, n = 0, e = 0, a = 1] = t;
    return `rgba(${Math.round(o * 255)}, ${Math.round(n * 255)}, ${Math.round(e * 255)}, ${a})`;
}
function ee(t) {
    return t.toString(16).padStart(2, 0);
}
function ne(t = []) {
    const [o = 0, n = 0, e = 0] = t;
    return `#${[o, n, e]
        .map(l => Math.round(l * 255))
        .map(l => l.toString(16).padStart(2, '0'))
        .join('')}`;
}
function oe(t = _t.RGBA_STRING) {
    const [o, n, e, a] = [Math.random(), Math.random(), Math.random(), Math.random()];
    if (t === _t.HEX_STRING) return ee([o, n, e]);
    if (t === _t.RGBA_STRING) return te([o, n, e, a]);
}
function pt(t, o, n) {
    const e = Ft(t),
        a = Ft(o);
    let l = a[0] * n + e[0] * (1 - n),
        f = a[1] * n + e[1] * (1 - n),
        y = a[2] * n + e[2] * (1 - n);
    return ne([l, f, y]);
}
const F = {
        CIRCLE_FULL: 'CIRCLE_FULL',
        CIRCLE_HALF: 'CIRCLE_HALF',
        CIRCLE_QURT: 'CIRCLE_QURT',
        DIAGONAL: 'DIAGONAL',
        SQUARE: 'SQUARE',
        CROSS: 'CROSS',
    },
    { PI: ut } = Math,
    yt = {
        CIRCLE_FULL: [0],
        CIRCLE_HALF: [0, ut],
        CIRCLE_QURT: [0, ut * 0.5, ut, ut * 1.5],
        DIAGONAL: [0, ut * 0.5],
        SQUARE: [0],
        CROSS: [0],
    },
    X = {
        COLOR_10: void 0,
        COLOR_20: void 0,
        COLOR_30: void 0,
        COLOR_70: void 0,
    },
    dt = {
        NONE: 'none',
        LETTER: 'letter',
        STRING: 'string',
    },
    Gt = 8;
function Ht() {
    let t = '0x';
    for (let o = 64; o > 0; --o) t += '0123456789abcdef'[~~(Math.random() * 16)];
    return t;
}
var Qt =
    typeof globalThis < 'u'
        ? globalThis
        : typeof window < 'u'
        ? window
        : typeof global < 'u'
        ? global
        : typeof self < 'u'
        ? self
        : {};
function re(t) {
    return t && t.__esModule && Object.prototype.hasOwnProperty.call(t, 'default') ? t.default : t;
}
var Pt = { exports: {} };
Pt.exports;
(function (t) {
    var o = 256,
        n = 6,
        e = 52,
        a = [],
        l = typeof Qt > 'u' ? window : Qt,
        f = Math.pow(o, n),
        y = Math.pow(2, e),
        h = y * 2,
        g = o - 1,
        M = Math.random;
    (t.exports = function (i, d) {
        if (d && d.global === !0) return (d.global = !1), (Math.random = t.exports(i, d)), (d.global = !0), Math.random;
        var u = (d && d.entropy) || !1,
            s = [];
        I($(u ? [i, C(a)] : 0 in arguments ? i : _(), 3), s);
        var r = new L(s);
        return (
            I(C(r.S), a),
            function () {
                for (var p = r.g(n), m = f, E = 0; p < y; ) (p = (p + E) * o), (m *= o), (E = r.g(1));
                for (; p >= h; ) (p /= 2), (m /= 2), (E >>>= 1);
                return (p + E) / m;
            }
        );
    }),
        (t.exports.resetGlobal = function () {
            Math.random = M;
        });
    function L(i) {
        var d,
            u = i.length,
            s = this,
            r = 0,
            p = (s.i = s.j = 0),
            m = (s.S = []);
        for (u || (i = [u++]); r < o; ) m[r] = r++;
        for (r = 0; r < o; r++) (m[r] = m[(p = g & (p + i[r % u] + (d = m[r])))]), (m[p] = d);
        (s.g = function (E) {
            for (var S, A = 0, b = s.i, D = s.j, N = s.S; E--; )
                (S = N[(b = g & (b + 1))]), (A = A * o + N[g & ((N[b] = N[(D = g & (D + S))]) + (N[D] = S))]);
            return (s.i = b), (s.j = D), A;
        })(o);
    }
    function $(i, d) {
        var u = [],
            s = (typeof i)[0],
            r;
        if (d && s == 'o')
            for (r in i)
                try {
                    u.push($(i[r], d - 1));
                } catch {}
        return u.length ? u : s == 's' ? i : i + '\0';
    }
    function I(i, d) {
        for (var u = i + '', s, r = 0; r < u.length; ) d[g & r] = g & ((s ^= d[g & r] * 19) + u.charCodeAt(r++));
        return C(d);
    }
    function _(i) {
        try {
            return l.crypto.getRandomValues((i = new Uint8Array(o))), C(i);
        } catch {
            return [+(/* @__PURE__ */ new Date()), l, l.navigator && l.navigator.plugins, l.screen, C(a)];
        }
    }
    function C(i) {
        return String.fromCharCode.apply(0, i);
    }
    I(Math.random(), a);
})(Pt);
var se = Pt.exports;
const ie = /* @__PURE__ */ re(se),
    ae = 0.5 * (Math.sqrt(3) - 1),
    ht = (3 - Math.sqrt(3)) / 6,
    ce = 1 / 3,
    W = 1 / 6,
    le = (Math.sqrt(5) - 1) / 4,
    z = (5 - Math.sqrt(5)) / 20,
    tt = t => Math.floor(t) | 0,
    Bt = /* @__PURE__ */ new Float64Array([
        1, 1, -1, 1, 1, -1, -1, -1, 1, 0, -1, 0, 1, 0, -1, 0, 0, 1, 0, -1, 0, 1, 0, -1,
    ]),
    Dt = /* @__PURE__ */ new Float64Array([
        1, 1, 0, -1, 1, 0, 1, -1, 0, -1, -1, 0, 1, 0, 1, -1, 0, 1, 1, 0, -1, -1, 0, -1, 0, 1, 1, 0, -1, 1, 0, 1, -1, 0,
        -1, -1,
    ]),
    gt = /* @__PURE__ */ new Float64Array([
        0, 1, 1, 1, 0, 1, 1, -1, 0, 1, -1, 1, 0, 1, -1, -1, 0, -1, 1, 1, 0, -1, 1, -1, 0, -1, -1, 1, 0, -1, -1, -1, 1,
        0, 1, 1, 1, 0, 1, -1, 1, 0, -1, 1, 1, 0, -1, -1, -1, 0, 1, 1, -1, 0, 1, -1, -1, 0, -1, 1, -1, 0, -1, -1, 1, 1,
        0, 1, 1, 1, 0, -1, 1, -1, 0, 1, 1, -1, 0, -1, -1, 1, 0, 1, -1, 1, 0, -1, -1, -1, 0, 1, -1, -1, 0, -1, 1, 1, 1,
        0, 1, 1, -1, 0, 1, -1, 1, 0, 1, -1, -1, 0, -1, 1, 1, 0, -1, 1, -1, 0, -1, -1, 1, 0, -1, -1, -1, 0,
    ]);
function ue(t = Math.random) {
    const o = jt(t),
        n = new Float64Array(o).map(a => Bt[(a % 12) * 2]),
        e = new Float64Array(o).map(a => Bt[(a % 12) * 2 + 1]);
    return function (l, f) {
        let y = 0,
            h = 0,
            g = 0;
        const M = (l + f) * ae,
            L = tt(l + M),
            $ = tt(f + M),
            I = (L + $) * ht,
            _ = L - I,
            C = $ - I,
            i = l - _,
            d = f - C;
        let u, s;
        i > d ? ((u = 1), (s = 0)) : ((u = 0), (s = 1));
        const r = i - u + ht,
            p = d - s + ht,
            m = i - 1 + 2 * ht,
            E = d - 1 + 2 * ht,
            S = L & 255,
            A = $ & 255;
        let b = 0.5 - i * i - d * d;
        if (b >= 0) {
            const G = S + o[A],
                U = n[G],
                P = e[G];
            (b *= b), (y = b * b * (U * i + P * d));
        }
        let D = 0.5 - r * r - p * p;
        if (D >= 0) {
            const G = S + u + o[A + s],
                U = n[G],
                P = e[G];
            (D *= D), (h = D * D * (U * r + P * p));
        }
        let N = 0.5 - m * m - E * E;
        if (N >= 0) {
            const G = S + 1 + o[A + 1],
                U = n[G],
                P = e[G];
            (N *= N), (g = N * N * (U * m + P * E));
        }
        return 70 * (y + h + g);
    };
}
function he(t = Math.random) {
    const o = jt(t),
        n = new Float64Array(o).map(l => Dt[(l % 12) * 3]),
        e = new Float64Array(o).map(l => Dt[(l % 12) * 3 + 1]),
        a = new Float64Array(o).map(l => Dt[(l % 12) * 3 + 2]);
    return function (f, y, h) {
        let g, M, L, $;
        const I = (f + y + h) * ce,
            _ = tt(f + I),
            C = tt(y + I),
            i = tt(h + I),
            d = (_ + C + i) * W,
            u = _ - d,
            s = C - d,
            r = i - d,
            p = f - u,
            m = y - s,
            E = h - r;
        let S, A, b, D, N, G;
        p >= m
            ? m >= E
                ? ((S = 1), (A = 0), (b = 0), (D = 1), (N = 1), (G = 0))
                : p >= E
                ? ((S = 1), (A = 0), (b = 0), (D = 1), (N = 0), (G = 1))
                : ((S = 0), (A = 0), (b = 1), (D = 1), (N = 0), (G = 1))
            : m < E
            ? ((S = 0), (A = 0), (b = 1), (D = 0), (N = 1), (G = 1))
            : p < E
            ? ((S = 0), (A = 1), (b = 0), (D = 0), (N = 1), (G = 1))
            : ((S = 0), (A = 1), (b = 0), (D = 1), (N = 1), (G = 0));
        const U = p - S + W,
            P = m - A + W,
            j = E - b + W,
            k = p - D + 2 * W,
            c = m - N + 2 * W,
            x = E - G + 2 * W,
            R = p - 1 + 3 * W,
            O = m - 1 + 3 * W,
            v = E - 1 + 3 * W,
            w = _ & 255,
            q = C & 255,
            H = i & 255;
        let Y = 0.6 - p * p - m * m - E * E;
        if (Y < 0) g = 0;
        else {
            const V = w + o[q + o[H]];
            (Y *= Y), (g = Y * Y * (n[V] * p + e[V] * m + a[V] * E));
        }
        let Z = 0.6 - U * U - P * P - j * j;
        if (Z < 0) M = 0;
        else {
            const V = w + S + o[q + A + o[H + b]];
            (Z *= Z), (M = Z * Z * (n[V] * U + e[V] * P + a[V] * j));
        }
        let J = 0.6 - k * k - c * c - x * x;
        if (J < 0) L = 0;
        else {
            const V = w + D + o[q + N + o[H + G]];
            (J *= J), (L = J * J * (n[V] * k + e[V] * c + a[V] * x));
        }
        let K = 0.6 - R * R - O * O - v * v;
        if (K < 0) $ = 0;
        else {
            const V = w + 1 + o[q + 1 + o[H + 1]];
            (K *= K), ($ = K * K * (n[V] * R + e[V] * O + a[V] * v));
        }
        return 32 * (g + M + L + $);
    };
}
function fe(t = Math.random) {
    const o = jt(t),
        n = new Float64Array(o).map(f => gt[(f % 32) * 4]),
        e = new Float64Array(o).map(f => gt[(f % 32) * 4 + 1]),
        a = new Float64Array(o).map(f => gt[(f % 32) * 4 + 2]),
        l = new Float64Array(o).map(f => gt[(f % 32) * 4 + 3]);
    return function (y, h, g, M) {
        let L, $, I, _, C;
        const i = (y + h + g + M) * le,
            d = tt(y + i),
            u = tt(h + i),
            s = tt(g + i),
            r = tt(M + i),
            p = (d + u + s + r) * z,
            m = d - p,
            E = u - p,
            S = s - p,
            A = r - p,
            b = y - m,
            D = h - E,
            N = g - S,
            G = M - A;
        let U = 0,
            P = 0,
            j = 0,
            k = 0;
        b > D ? U++ : P++,
            b > N ? U++ : j++,
            b > G ? U++ : k++,
            D > N ? P++ : j++,
            D > G ? P++ : k++,
            N > G ? j++ : k++;
        const c = U >= 3 ? 1 : 0,
            x = P >= 3 ? 1 : 0,
            R = j >= 3 ? 1 : 0,
            O = k >= 3 ? 1 : 0,
            v = U >= 2 ? 1 : 0,
            w = P >= 2 ? 1 : 0,
            q = j >= 2 ? 1 : 0,
            H = k >= 2 ? 1 : 0,
            Y = U >= 1 ? 1 : 0,
            Z = P >= 1 ? 1 : 0,
            J = j >= 1 ? 1 : 0,
            K = k >= 1 ? 1 : 0,
            V = b - c + z,
            Mt = D - x + z,
            Rt = N - R + z,
            Ct = G - O + z,
            mt = b - v + 2 * z,
            bt = D - w + 2 * z,
            $t = N - q + 2 * z,
            Lt = G - H + 2 * z,
            It = b - Y + 3 * z,
            St = D - Z + 3 * z,
            vt = N - J + 3 * z,
            Et = G - K + 3 * z,
            At = b - 1 + 4 * z,
            wt = D - 1 + 4 * z,
            Ot = N - 1 + 4 * z,
            Tt = G - 1 + 4 * z,
            et = d & 255,
            nt = u & 255,
            ot = s & 255,
            rt = r & 255;
        let st = 0.6 - b * b - D * D - N * N - G * G;
        if (st < 0) L = 0;
        else {
            const Q = et + o[nt + o[ot + o[rt]]];
            (st *= st), (L = st * st * (n[Q] * b + e[Q] * D + a[Q] * N + l[Q] * G));
        }
        let it = 0.6 - V * V - Mt * Mt - Rt * Rt - Ct * Ct;
        if (it < 0) $ = 0;
        else {
            const Q = et + c + o[nt + x + o[ot + R + o[rt + O]]];
            (it *= it), ($ = it * it * (n[Q] * V + e[Q] * Mt + a[Q] * Rt + l[Q] * Ct));
        }
        let at = 0.6 - mt * mt - bt * bt - $t * $t - Lt * Lt;
        if (at < 0) I = 0;
        else {
            const Q = et + v + o[nt + w + o[ot + q + o[rt + H]]];
            (at *= at), (I = at * at * (n[Q] * mt + e[Q] * bt + a[Q] * $t + l[Q] * Lt));
        }
        let ct = 0.6 - It * It - St * St - vt * vt - Et * Et;
        if (ct < 0) _ = 0;
        else {
            const Q = et + Y + o[nt + Z + o[ot + J + o[rt + K]]];
            (ct *= ct), (_ = ct * ct * (n[Q] * It + e[Q] * St + a[Q] * vt + l[Q] * Et));
        }
        let lt = 0.6 - At * At - wt * wt - Ot * Ot - Tt * Tt;
        if (lt < 0) C = 0;
        else {
            const Q = et + 1 + o[nt + 1 + o[ot + 1 + o[rt + 1]]];
            (lt *= lt), (C = lt * lt * (n[Q] * At + e[Q] * wt + a[Q] * Ot + l[Q] * Tt));
        }
        return 27 * (L + $ + I + _ + C);
    };
}
function jt(t) {
    const n = new Uint8Array(512);
    for (let e = 0; e < 512 / 2; e++) n[e] = e;
    for (let e = 0; e < 512 / 2 - 1; e++) {
        const a = e + ~~(t() * (256 - e)),
            l = n[e];
        (n[e] = n[a]), (n[a] = l);
    }
    for (let e = 256; e < 512; e++) n[e] = n[e - 256];
    return n;
}
class de {
    constructor(o) {
        (this.noise2D = ue(o)), (this.noise3D = he(o)), (this.noise4D = fe(o));
    }
}
function Ut(t = null) {
    let o = Math.random,
        n,
        e,
        a,
        l = null,
        f = !1;
    return (
        y(t),
        {
            value: h,
            createRandom: function (c) {
                return Ut(c);
            },
            setSeed: y,
            getSeed: M,
            getRandomSeed: L,
            valueNonZero: g,
            permuteNoise: I,
            noise1D: _,
            noise2D: C,
            noise3D: i,
            noise4D: d,
            sign: u,
            boolean: s,
            chance: r,
            range: p,
            rangeFloor: m,
            pick: E,
            shuffle: S,
            onCircle: A,
            insideCircle: b,
            onSphere: D,
            insideSphere: N,
            quaternion: G,
            weighted: j,
            weightedSet: U,
            weightedSetIndex: P,
            gaussian: k,
        }
    );
    function y(c, x) {
        typeof c == 'number' || typeof c == 'string' ? ((n = c), (e = ie(n, x))) : ((n = void 0), (e = o)),
            (a = $()),
            (l = null),
            (f = !1);
    }
    function h() {
        return e();
    }
    function g() {
        for (var c = 0; c === 0; ) c = h();
        return c;
    }
    function M() {
        return n;
    }
    function L() {
        var c = String(Math.floor(Math.random() * 1e6));
        return c;
    }
    function $() {
        return new de(e);
    }
    function I() {
        a = $();
    }
    function _(c, x = 1, R = 1) {
        if (!isFinite(c)) throw new TypeError('x component for noise() must be finite');
        return R * a.noise2D(c * x, 0);
    }
    function C(c, x, R = 1, O = 1) {
        if (!isFinite(c)) throw new TypeError('x component for noise() must be finite');
        if (!isFinite(x)) throw new TypeError('y component for noise() must be finite');
        return O * a.noise2D(c * R, x * R);
    }
    function i(c, x, R, O = 1, v = 1) {
        if (!isFinite(c)) throw new TypeError('x component for noise() must be finite');
        if (!isFinite(x)) throw new TypeError('y component for noise() must be finite');
        if (!isFinite(R)) throw new TypeError('z component for noise() must be finite');
        return v * a.noise3D(c * O, x * O, R * O);
    }
    function d(c, x, R, O, v = 1, w = 1) {
        if (!isFinite(c)) throw new TypeError('x component for noise() must be finite');
        if (!isFinite(x)) throw new TypeError('y component for noise() must be finite');
        if (!isFinite(R)) throw new TypeError('z component for noise() must be finite');
        if (!isFinite(O)) throw new TypeError('w component for noise() must be finite');
        return w * a.noise4D(c * v, x * v, R * v, O * v);
    }
    function u() {
        return s() ? 1 : -1;
    }
    function s() {
        return h() > 0.5;
    }
    function r(c = 0.5) {
        if (typeof c != 'number') throw new TypeError('expected n to be a number');
        return h() < c;
    }
    function p(c, x) {
        if ((x === void 0 && ((x = c), (c = 0)), typeof c != 'number' || typeof x != 'number'))
            throw new TypeError('Expected all arguments to be numbers');
        return h() * (x - c) + c;
    }
    function m(c, x) {
        if ((x === void 0 && ((x = c), (c = 0)), typeof c != 'number' || typeof x != 'number'))
            throw new TypeError('Expected all arguments to be numbers');
        return Math.floor(p(c, x));
    }
    function E(c) {
        if (c.length !== 0) return c[m(0, c.length)];
    }
    function S(c) {
        if (!Array.isArray(c)) throw new TypeError('Expected Array, got ' + typeof c);
        for (var x, R, O = c.length, v = c.slice(); O; )
            (x = Math.floor(h() * O--)), (R = v[O]), (v[O] = v[x]), (v[x] = R);
        return v;
    }
    function A(c = 1, x = []) {
        var R = h() * 2 * Math.PI;
        return (x[0] = c * Math.cos(R)), (x[1] = c * Math.sin(R)), x;
    }
    function b(c = 1, x = []) {
        A(1, x);
        var R = c * Math.sqrt(h());
        return (x[0] *= R), (x[1] *= R), x;
    }
    function D(c = 1, x = []) {
        var R = h() * Math.PI * 2,
            O = h() * 2 - 1,
            v = R,
            w = Math.acos(O);
        return (
            (x[0] = c * Math.sin(w) * Math.cos(v)), (x[1] = c * Math.sin(w) * Math.sin(v)), (x[2] = c * Math.cos(w)), x
        );
    }
    function N(c = 1, x = []) {
        var R = h() * Math.PI * 2,
            O = h() * 2 - 1,
            v = h(),
            w = R,
            q = Math.acos(O),
            H = c * Math.cbrt(v);
        return (
            (x[0] = H * Math.sin(q) * Math.cos(w)), (x[1] = H * Math.sin(q) * Math.sin(w)), (x[2] = H * Math.cos(q)), x
        );
    }
    function G(c) {
        var x = h(),
            R = h(),
            O = h(),
            v = Math.sqrt(1 - x),
            w = Math.sqrt(x),
            q = Math.PI * 2 * R,
            H = Math.PI * 2 * O,
            Y = Math.sin(q) * v,
            Z = Math.cos(q) * v,
            J = Math.sin(H) * w,
            K = Math.cos(H) * w;
        return (c[0] = Y), (c[1] = Z), (c[2] = J), (c[3] = K), c;
    }
    function U(c = []) {
        return c.length === 0 ? null : c[P(c)].value;
    }
    function P(c = []) {
        return c.length === 0
            ? -1
            : j(
                  c.map(function (x) {
                      return x.weight;
                  }),
              );
    }
    function j(c = []) {
        if (c.length === 0) return -1;
        var x = 0,
            R;
        for (R = 0; R < c.length; R++) x += c[R];
        if (x <= 0) throw new Error('Weights must sum to > 0');
        var O = h() * x;
        for (R = 0; R < c.length; R++) {
            if (O < c[R]) return R;
            O -= c[R];
        }
        return 0;
    }
    function k(c = 0, x = 1) {
        if (f) {
            f = !1;
            var R = l;
            return (l = null), c + x * R;
        } else {
            var O = 0,
                v = 0,
                w = 0;
            do (O = h() * 2 - 1), (v = h() * 2 - 1), (w = O * O + v * v);
            while (w >= 1 || w === 0);
            var q = Math.sqrt((-2 * Math.log(w)) / w);
            return (l = v * q), (f = !0), c + x * (O * q);
        }
    }
}
Ut();
let xt = Ht(),
    B = Ut(xt);
B.reset = () => {
    B.setSeed(xt);
};
B.change = (t = Ht()) => {
    (xt = t), B.setSeed(xt);
};
const Yt = 'url(#gradient-top-left)',
    Xt = 'url(#gradient-bottom-left)',
    Zt = 'url(#gradient-top-right)',
    Jt = 'url(#gradient-bottom-right)',
    ft = (t = []) => B.pick([Xt, Jt, Yt, Zt].filter(o => !t.includes(o))),
    pe = ({
        width: t,
        height: o,
        columns: n,
        rows: e,
        columnGap: a,
        rowGap: l,
        margin: f = { x: 0, y: 0 },
        excludes: y = [],
    }) => {
        const h = [],
            g = [],
            M = [];
        let L = (t - f.x * 2 - (n - 1) * a) / n,
            $ = (o - f.y * 2 - (e - 1) * l) / e;
        const I = [
                F.SQUARE,
                F.CIRCLE_QURT,
                // TYPES.CIRCLE_HALF,
            ],
            _ = n * e,
            C = [];
        for (let u = 0; u < _; u++) {
            let s = Math.floor(u / n),
                r = u % e,
                p = f.x + L * s + a * s,
                m = f.y + $ * r + l * r;
            const E = B.pick(I),
                S = B.pick(yt[`${E}`]),
                A =
                    E === F.SQUARE
                        ? B.weightedSet([
                              { value: X.COLOR_20, weight: 80 },
                              { value: ft(), weight: 20 },
                          ])
                        : B.weightedSet([
                              { value: X.COLOR_70, weight: 80 },
                              { value: ft(), weight: 20 },
                          ]),
                b = {
                    index: u,
                    x: p,
                    y: m,
                    column: s,
                    row: r,
                    width: L,
                    height: $,
                    type: E,
                    rotation: S,
                    fill: A,
                };
            (y[s] && y[s][r]) || (C[s] || (C[s] = []), (C[s][r] = b), h.push(b));
        }
        const i = (u, s) => {
                if (C[u]) return C[u][s];
            },
            d = (u, s) =>
                [i(u, s), i(u + 1, s), i(u + 1, s + 1), i(u, s + 1)].filter(r => r !== void 0).map(r => r.index);
        for (let u = 0; u < _; u++) {
            let s = Math.floor(u / n),
                r = u % e,
                p = f.x + L * s + a * s,
                m = f.y + $ * r + l * r;
            if (s < n - 1 && r < e - 1) {
                const E = B.pick([
                        F.CROSS,
                        F.CIRCLE_FULL,
                        F.DIAGONAL,
                        // TYPES.CIRCLE_HALF,
                    ]),
                    S = B.pick(yt[`${E}`]);
                let A = [];
                E === F.DIAGONAL && (S === 0 ? A.push(Zt, Xt) : A.push(Yt, Jt));
                const b =
                        E === F.DIAGONAL
                            ? B.weightedSet([
                                  { value: X.COLOR_10, weight: 80 },
                                  {
                                      value: ft(A),
                                      weight: 20,
                                  },
                              ])
                            : X.COLOR_10,
                    D = {
                        index: u,
                        x: p,
                        y: m,
                        cells: d(s, r),
                        neighboursCells: [...d(s + 2, r), ...d(s - 2, r), ...d(s, r + 2), ...d(s, r - 2)],
                        width: L * 2 + a,
                        height: $ * 2 + l,
                        neighbours: [u + 1, u + e + 1, u + e, u + e - 1, u - 1, u - e + 1, u - e - 1, u - e],
                        type: E,
                        rotation: S,
                        fill: b,
                        column: s,
                        row: r,
                        isNode: !0,
                    };
                D.cells.length === 4 && g.push(D);
            }
        }
        for (let u = 0; u < _; u++) {
            let s = Math.floor(u / n),
                r = u % e,
                p = f.x + L * s + a * s,
                m = f.y + $ * r + l * r;
            const E = oe();
            if (s < n - 1) {
                let S = {
                    index: M.length,
                    type: F.CIRCLE_HALF,
                    x: p,
                    y: m,
                    width: L * 2 + a,
                    cells: [i(s, r), i(s + 1, r)].filter(b => b !== void 0).map(b => b.index),
                    neighbours: [],
                    neighboursCells: [
                        i(s - 1, r - 1),
                        i(s, r - 1),
                        i(s + 1, r - 1),
                        i(s + 2, r - 1),
                        i(s + 2, r),
                        i(s + 2, r + 1),
                        i(s + 1, r + 1),
                        i(s, r + 1),
                        i(s - 1, r + 1),
                        i(s - 1, r),
                    ]
                        .filter(b => b !== void 0)
                        .map(b => b.index),
                    height: $,
                    color: E,
                    rotation: B.pick(yt[`${F.CIRCLE_HALF}`]),
                    fill: B.weightedSet([
                        { value: X.COLOR_30, weight: 80 },
                        { value: ft(), weight: 20 },
                    ]),
                    column: s,
                    row: r,
                    isDouble: !0,
                };
                S.cells.length === 2 && M.push(S);
            }
            if (r < e - 1) {
                let S = {
                    index: M.length,
                    type: F.CIRCLE_HALF,
                    x: p,
                    y: m,
                    width: L,
                    height: $ * 2 + l,
                    cells: [i(s, r), i(s, r + 1)].filter(b => b !== void 0).map(b => b.index),
                    neighbours: [],
                    neighboursCells: [
                        i(s - 1, r - 1),
                        i(s, r - 1),
                        i(s + 1, r - 1),
                        i(s + 1, r),
                        i(s + 1, r + 1),
                        i(s + 1, r + 2),
                        i(s, r + 2),
                        i(s - 1, r + 2),
                        i(s - 1, r + 1),
                        i(s - 1, r),
                    ]
                        .filter(b => b !== void 0)
                        .map(b => b.index),
                    fill: B.weightedSet([
                        { value: X.COLOR_30, weight: 80 },
                        { value: ft(), weight: 20 },
                    ]),
                    rotation: B.pick(yt[`${F.CIRCLE_HALF}`]),
                    column: s,
                    row: r,
                    isDouble: !0,
                };
                S.cells.length === 2 && M.push(S);
            }
        }
        return { cells: h, nodes: g, doubles: M, cellWidth: L, cellHeight: $ };
    };
function ye(t, o) {
    (X.COLOR_10 = pt(t, o, 0.9)),
        (X.COLOR_20 = pt(t, o, 0.8)),
        (X.COLOR_30 = pt(t, o, 0.7)),
        (X.COLOR_70 = pt(t, o, 0.3));
}
const ge = `
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟥
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟥
  🟥  🟩  🟩  🟥  🟥  🟩  🟩  🟥
  🟥  🟩  🟩  🟥  🟥  🟩  🟩  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  `,
    xe = `
  🟩  🟩  🟩  🟩  🟩  🟩  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟥  🟥
  `,
    Me = `
  🟥  🟥  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟥  🟥  🟩  🟩  🟩  🟩  🟩  🟩
  `,
    Re = `
  🟩  🟩  🟩  🟩  🟩  🟩  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟥  🟥
  `,
    Ce = `
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟥  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  `,
    me = `
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟥  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  `,
    be = `
  🟥  🟥  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟥  🟥  🟩  🟩  🟩  🟩  🟩  🟩
  `,
    $e = `
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  `,
    Le = `
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  `,
    Ie = `
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟥  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟥  🟥  🟥
  `,
    Se = `
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟩  🟩  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟥  🟥
  🟩  🟩  🟥  🟥  🟩  🟩  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  `,
    ve = `
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  `,
    Ee = `
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟩  🟥  🟥  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟩  🟩  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  `,
    Ae = `
  🟩  🟩  🟩  🟩  🟥  🟥  🟩  🟩
  🟩  🟩  🟩  🟩  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟩  🟩  🟥  🟩  🟩
  🟩  🟩  🟥  🟩  🟩  🟥  🟩  🟩
  🟩  🟩  🟥  🟩  🟩  🟥  🟩  🟩
  🟩  🟩  🟥  🟩  🟩  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟩  🟩  🟩  🟩
  `,
    we = `
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟥
  `,
    Oe = `
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  `,
    Te = `
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟩  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟥
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟩
  `,
    _e = `
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟥  🟥
  🟩  🟩  🟥  🟥  🟩  🟩  🟩  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  `,
    De = `
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟥  🟥  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  `,
    Ne = `
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  `,
    Fe = `
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟥  🟩  🟩  🟩  🟩  🟩  🟩  🟥
  🟥  🟩  🟩  🟩  🟩  🟩  🟩  🟥
  `,
    Ge = `
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟥  🟩  🟩  🟥  🟥  🟩  🟩  🟥
  🟥  🟩  🟩  🟥  🟥  🟩  🟩  🟥
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟥
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  `,
    Pe = `
  🟩  🟩  🟥  🟩  🟩  🟥  🟩  🟩
  🟩  🟩  🟥  🟩  🟩  🟥  🟩  🟩
  🟩  🟩  🟥  🟩  🟩  🟥  🟩  🟩
  🟩  🟩  🟥  🟩  🟩  🟥  🟩  🟩
  🟩  🟩  🟥  🟩  🟩  🟥  🟩  🟩
  🟩  🟩  🟥  🟩  🟩  🟥  🟩  🟩
  🟥  🟩  🟩  🟥  🟥  🟩  🟩  🟥
  🟥  🟩  🟩  🟥  🟥  🟩  🟩  🟥
  `,
    je = `
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟥
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟥
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟥
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  `,
    Ue = `
  🟩  🟩  🟥  🟥  🟥  🟥  🟩  🟩
  🟥  🟩  🟥  🟥  🟥  🟥  🟩  🟥
  🟥  🟩  🟩  🟥  🟥  🟩  🟩  🟥
  🟥  🟥  🟩  🟩  🟩  🟩  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  🟥  🟥  🟥  🟩  🟩  🟥  🟥  🟥
  `,
    ke = `
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟥  🟥  🟥  🟥  🟥  🟥  🟩  🟩
  🟥  🟥  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟥  🟥
  🟩  🟩  🟥  🟥  🟥  🟥  🟥  🟥
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  🟩  🟩  🟩  🟩  🟩  🟩  🟩  🟩
  `,
    Kt = {
        A: ge,
        B: xe,
        C: Me,
        D: Re,
        E: Ce,
        F: me,
        G: be,
        H: $e,
        I: Le,
        J: Ie,
        K: Se,
        L: ve,
        M: Ee,
        N: Ae,
        O: we,
        P: Oe,
        Q: Te,
        R: _e,
        S: De,
        T: Ne,
        U: Fe,
        V: Ge,
        W: Pe,
        X: je,
        Y: Ue,
        Z: ke,
    },
    Qe = Object.keys(Kt);
function Be(t) {
    let o = t[0].toUpperCase();
    if (Qe.includes(o)) {
        let a = Kt[`${o}`]
            .trim()
            .split(
                `
  `,
            )
            .reduce((y, h, g) => {
                const M = h.split(' ').filter(L => L !== '');
                return (y[g] = M), y;
            }, []);
        if (!(a.length === Gt && a.every(y => y.length === Gt)))
            return console.error(`Data incorrect for letter ${t}`), [];
        let f = [];
        for (let y = 0; y < a.length; y++) {
            const h = y,
                g = a[h];
            for (let M = 0; M < g.length; M++) {
                const L = M,
                    $ = g[M];
                f[L] || (f[L] = []), (f[L][h] = $ === '🟥');
            }
        }
        return f;
    }
    return [];
}
function qt(t, o) {
    const n = o.x - t.x,
        e = o.y - t.y,
        a = Math.hypot(n, e),
        l = n * n + e * e,
        f = a !== 0 ? { x: n / a, y: e / a } : { x: 0, y: 0 },
        y = kt(f, { x: 0, y: 0 }, Math.PI / 2),
        h = Math.atan2(e, n),
        g = 2 * Math.PI + (Math.round(h) % (2 * Math.PI)),
        M = (180 * h) / Math.PI,
        L = (360 + Math.round(M)) % 360;
    return {
        dx: n,
        dy: e,
        mag: a,
        magSq: l,
        unit: f,
        normal: y,
        p1: { ...t },
        p2: { ...o },
        angle: {
            radians: h,
            normalized: g,
            degrees: M,
            degreesNormalized: L,
        },
    };
}
function Vt(t, o = 2) {
    return Math.round(t * 10 ** o) / 10 ** o;
}
function kt(t, o, n) {
    const e = Math.cos(n),
        a = Math.sin(n),
        l = e * (t.x - o.x) + a * (t.y - o.y) + o.x,
        f = e * (t.y - o.y) - a * (t.x - o.x) + o.y;
    return { x: l, y: f };
}
function zt(t, o, n = 'circle') {
    if (!t || o === 0 || (Array.isArray(o) && o.every(l => l === 0))) return t;
    let e = Ve(t).filter(l => l[0] !== 'Z');
    const a = [];
    for (let l = 0; l < e.length; l++) {
        const f = (l + 1) % e.length,
            y = (l + 2) % e.length;
        let [h, g, M] = e[l],
            [L, $, I] = e[f],
            [_, C, i] = e[y];
        const d = {
                x: g,
                y: M,
            },
            u = { x: $, y: I },
            s = { x: C, y: i },
            r = qt(u, d),
            p = qt(u, s),
            m = Math.abs(
                Math.atan2(
                    r.dx * p.dy - r.dy * p.dx,
                    // cross product
                    r.dx * p.dx + r.dy * p.dy,
                    // dot product
                ),
            ),
            E = Array.isArray(o) ? o[l] : o;
        let S = Math.min(E, r.mag / 2, p.mag / 2);
        if (E === 0) {
            a.push(`L ${d.x} ${d.y}`), a.push(`L ${u.x} ${u.y}`);
            continue;
        }
        const A = S,
            b = Math.cos(m / 2) * A,
            D = Math.sin(m / 2) * b,
            N = Math.cos(m / 2) * b,
            U = D / (N / A),
            P = Math.ceil((2 * Math.PI) / (Math.PI - m));
        let j;
        n === 'circle'
            ? (j = (4 / 3) * Math.tan(Math.PI / (2 * P)) * U)
            : n === 'approx'
            ? (j =
                  (4 / 3) *
                  Math.tan(Math.PI / (2 * ((2 * Math.PI) / m))) *
                  S *
                  (m < Math.PI / 2 ? 1 + Math.cos(m) : 2 - Math.sin(m)))
            : n === 'hand' && (j = (4 / 3) * Math.tan(Math.PI / (2 * ((2 * Math.PI) / m))) * S * (2 + Math.sin(m)));
        const k = S - j;
        let c = {
                x: u.x + r.unit.x * S,
                y: u.y + r.unit.y * S,
            },
            x = {
                x: u.x + r.unit.x * k,
                y: u.y + r.unit.y * k,
            },
            R = {
                x: u.x + p.unit.x * S,
                y: u.y + p.unit.y * S,
            },
            O = {
                x: u.x + p.unit.x * k,
                y: u.y + p.unit.y * k,
            };
        const v = w => ({
            x: Vt(w.x, 3),
            y: Vt(w.y, 3),
        });
        (c = v(c)),
            (x = v(x)),
            (R = v(R)),
            (O = v(O)),
            l === e.length - 1 && a.unshift(`M ${R.x} ${R.y}`),
            a.push(`L ${c.x} ${c.y}`),
            a.push(`C ${x.x} ${x.y}, ${O.x} ${O.y}, ${R.x} ${R.y}`);
    }
    return a.push('Z'), a.join(' ');
}
var Nt = { a: 7, c: 6, h: 1, l: 2, m: 2, q: 4, s: 4, t: 2, v: 1, z: 0 },
    qe = /([astvzqmhlc])([^astvzqmhlc]*)/gi;
function Ve(t) {
    var o = [];
    return (
        t.replace(qe, function (n, e, a) {
            var l = e.toLowerCase();
            for (
                a = He(a),
                    l == 'm' &&
                        a.length > 2 &&
                        (o.push([e].concat(a.splice(0, 2))), (l = 'l'), (e = e == 'm' ? 'l' : 'L'));
                ;

            ) {
                if (a.length == Nt[l]) return a.unshift(e), o.push(a);
                if (a.length < Nt[l]) throw new Error('malformed path data');
                o.push([e].concat(a.splice(0, Nt[l])));
            }
        }),
        o
    );
}
var ze = /-?[0-9]*\.?[0-9]+(?:e[-+]?\d+)?/gi;
function He(t) {
    var o = t.match(ze);
    return o ? o.map(Number) : [];
}
function Ye({ x: t = 0, y: o = 0, width: n = 1, height: e = 1, fill: a, attributes: l = {} }) {
    return {
        type: 'circle',
        attributes: {
            ...l,
            cx: t + n * 0.5,
            cy: o + e * 0.5,
            r: n * 0.5,
            fill: a,
        },
    };
}
function Xe({ x: t = 0, y: o = 0, width: n = 1, height: e = 1, fill: a = 'red', radius: l, attributes: f = {} }) {
    return {
        type: 'rect',
        attributes: { ...f, x: t, y: o, width: n, height: e, fill: a, rx: l },
    };
}
function Ze({
    x: t = 0,
    y: o = 0,
    width: n = 1,
    height: e = 1,
    radius: a,
    fill: l,
    rotation: f = 0,
    attributes: y = {},
}) {
    const h = a,
        M = Math.min(n, e),
        L = M - h,
        $ = h,
        I = Math.sqrt(L * L - h * h),
        _ = Math.atan($ / I),
        C = Math.sin(_) * M,
        i = Math.cos(_) * M;
    let d = [
        [h, e],
        [I, e],
        [i, e - C],
        [C, e - i],
        [0, e - I],
        [0, e - h],
        [h, e],
    ];
    d = d
        .map(([s, r]) => ({ x: s, y: r }))
        .map(s => kt(s, { x: M * 0.5, y: M * 0.5 }, f))
        .map(s => [s.x + t, s.y + o]);
    let u = '';
    return (
        (u += `M ${d[0][0]} ${d[0][1]}`),
        (u += `L ${d[1][0]} ${d[1][1]}`),
        (u += `A ${h} ${h} 0 0 0 ${d[2][0]} ${d[2][1]}`),
        (u += `A ${M} ${M} 0 0 0 ${d[3][0]} ${d[3][1]}`),
        (u += `A ${h} ${h} 0 0 0 ${d[4][0]} ${d[4][1]}`),
        (u += `L ${d[5][0]} ${d[5][1]}`),
        (u += `A ${h} ${h} 0 0 0 ${d[6][0]} ${d[6][1]}`),
        {
            type: 'path',
            attributes: {
                ...y,
                d: u,
                fill: l,
            },
        }
    );
}
function Je({
    x: t = 0,
    y: o = 0,
    width: n = 1,
    height: e = 1,
    radius: a = 0,
    rotation: l = 0,
    fill: f = 'red',
    attributes: y = {},
}) {
    const h = a,
        g = Math.max(n, e),
        M = Math.min(e, n),
        L = n * (n > e ? 0.5 : 1),
        $ = e * (n < e ? 0.5 : 1),
        I = n > e ? L : $,
        _ = I - h,
        C = h,
        i = Math.sqrt(_ * _ - h * h),
        d = Math.atan(C / i),
        u = Math.sin(d) * I,
        s = Math.cos(d) * I;
    let r = '',
        p =
            n > e
                ? [
                      [g * 0.5 - i, M],
                      [g * 0.5 + i, M],
                      [g * 0.5 + s, M - u],
                      [g * 0.5 - s, M - u],
                      [g * 0.5 - i, M],
                  ]
                : [
                      [M, g * 0.5 + i],
                      [M, g * 0.5 - i],
                      [M - u, g * 0.5 - s],
                      [M - u, g * 0.5 + s],
                      [M, g * 0.5 + i],
                  ];
    return (
        (p = p
            .map(([m, E]) => ({ x: m, y: E }))
            .map(m => kt(m, { x: n * 0.5, y: e * 0.5 }, l))
            .map(m => [m.x + t, m.y + o])),
        (r += `M ${p[0][0]} ${p[0][1]}`),
        (r += `L ${p[1][0]} ${p[1][1]}`),
        (r += `A ${h} ${h} 0 0 0 ${p[2][0]} ${p[2][1]}`),
        (r += `A ${L} ${$} 0 0 0 ${p[3][0]} ${p[3][1]}`),
        (r += `A ${h} ${h} 0 0 0 ${p[4][0]} ${p[4][1]}`),
        (r += 'Z'),
        {
            type: 'path',
            attributes: {
                ...y,
                d: r,
                fill: f,
            },
        }
    );
}
function Ke({
    x: t,
    y: o,
    width: n,
    height: e,
    type: a,
    rotation: l,
    fill: f,
    squareRadius: y = 12,
    crossRadius: h = 6,
    circleRadius: g = 4,
    gap: M = 10,
}) {
    const L = {
        'data-x': t,
        'data-y': o,
        'data-width': n,
        'data-height': e,
    };
    switch (a) {
        case F.SQUARE:
            return Xe({
                x: t,
                y: o,
                width: n,
                height: e,
                radius: (y / 100) * n,
                fill: f,
                attributes: L,
            });
        case F.CIRCLE_FULL:
            return Ye({
                x: t,
                y: o,
                width: n,
                height: e,
                fill: f,
                attributes: L,
            });
        case F.DIAGONAL:
        case F.CROSS:
            const $ = (n - M) / 2,
                I = Math.sqrt($ * $ + $ * $),
                _ = e * 0.5 + Math.tan(Math.PI * 0.25) * (n * 0.5 - I * 0.5),
                C = n - _;
            let i = [];
            if (a === F.DIAGONAL) {
                (i =
                    l === 0
                        ? [
                              [0, 0],
                              [C, 0],
                              [n, e - C],
                              [n, e],
                              [n - C, e],
                              [0, C],
                          ]
                        : [
                              [n, 0],
                              [n, C],
                              [C, e],
                              [0, e],
                              [0, e - C],
                              [n - C, 0],
                          ]),
                    (i = i.map(([r, p]) => [r + t, p + o]));
                let d = i.map(([r, p], m) => `${m === 0 ? 'M' : 'L'} ${r} ${p}`).join(' ');
                d += ' Z';
                const u = (h / 100) * n * 0.5,
                    s = u * 0.5;
                return (
                    (d = zt(d, [s, s, u, s, s, u])),
                    {
                        type: 'path',
                        attributes: {
                            ...L,
                            d,
                            fill: f,
                        },
                    }
                );
            } else if (a === F.CROSS) {
                const d = (n - I) * 0.5,
                    u = (e - I) * 0.5;
                (i = [
                    [0, 0],
                    [C, 0],
                    [n * 0.5, u],
                    [n - C, 0],
                    [n, 0],
                    [n, C],
                    [n - d, e * 0.5],
                    [n, e - C],
                    [n, e],
                    [n - C, e],
                    [n * 0.5, e - u],
                    [C, e],
                    [0, e],
                    [0, n - C],
                    [d, e * 0.5],
                    [0, C],
                ]),
                    (i = i.map(([E, S]) => [E + t, S + o]));
                let s = i.map(([E, S], A) => `${A === 0 ? 'M' : 'L'} ${E} ${S}`).join(' ');
                s += ' Z';
                const r = (h / 100) * n * 0.5,
                    p = r * 0.5,
                    m = r;
                return (
                    (s = zt(s, [
                        p,
                        0,
                        p,
                        m,
                        p,
                        0,
                        p,
                        m,
                        p,
                        0,
                        p,
                        m,
                        p,
                        0,
                        p,
                        m,
                        p,
                        0,
                        // 0,
                    ])),
                    (f = X.COLOR_10),
                    {
                        type: 'path',
                        attributes: {
                            ...L,
                            d: s,
                            fill: f,
                        },
                    }
                );
            }
        case F.CIRCLE_QURT:
            return Ze({
                x: t,
                y: o,
                width: n,
                height: e,
                radius: (g / 100) * n,
                rotation: l,
                fill: f,
                attributes: L,
            });
        case F.CIRCLE_HALF:
            return Je({
                x: t,
                y: o,
                width: n,
                height: e,
                radius: ((g * 0.5) / 100) * Math.max(n, e),
                rotation: l,
                fill: f,
                attributes: L,
            });
    }
}
function We(
    t,
    {
        offsetX: o,
        offsetsRows: n = [],
        offsetY: e,
        offsetsCols: a = [],
        columns: l,
        rows: f,
        margin: y = { x: 0, y: 0 },
        cellWidth: h,
        cellHeight: g,
        columnGap: M,
        rowGap: L,
        force: $ = !1,
    } = {},
) {
    let I = [],
        _ = (o + n[t.row]) % l;
    _ = _ >= 0 ? _ : _ + l;
    let C = (e + a[t.column]) % f;
    C = C >= 0 ? C : C + f;
    let i = t.column;
    (i += _), (i %= l);
    let d = t.row;
    (d += C), (d %= f);
    let u = h * l + M * (l - 1),
        s = g * f + L * (f - 1),
        r = y.x + u,
        p = y.y + s;
    const m = c => y.x + h * (((c % l) + l) % l) + M * (((c % l) + l) % l),
        E = c => y.y + g * (((c % f) + f) % f) + L * (((c % f) + f) % f);
    let S = m(i),
        A = E(d),
        b = t.isDouble && S + t.width > r,
        D = t.isDouble && t.width > t.height && Math.abs(a[t.column] % f) !== Math.abs(a[t.column + 1] % f),
        N = b || D,
        G = t.isDouble && A + t.height > p,
        U = t.isDouble && t.width < t.height && Math.abs(n[t.row] % l) !== Math.abs(n[t.row + 1] % l),
        P = G || U,
        j = t.isNode && S + t.width > r,
        k = t.isNode && A + t.height > p;
    if (N)
        I.push(
            {
                ...t,
                x: S,
                y: A,
                isDouble: !1,
                type: F.CIRCLE_QURT,
                width: h,
                height: g,
                rotation: t.rotation === 0 ? Math.PI * 0.5 : Math.PI,
            },
            {
                ...t,
                x: m(i + 1),
                y: E(d - a[t.column] + a[t.column + 1]),
                isDouble: !1,
                type: F.CIRCLE_QURT,
                width: h,
                height: g,
                rotation: t.rotation === 0 ? 0 : Math.PI * 1.5,
            },
        );
    else if (P)
        I.push(
            {
                ...t,
                x: S,
                y: A,
                type: F.CIRCLE_QURT,
                isDouble: !1,
                width: h,
                height: g,
                fill: t.fill,
                rotation: t.rotation === 0 ? Math.PI * 0.5 : 0,
            },
            {
                ...t,
                x: m(i - n[t.row] + n[t.row + 1]),
                y: E(d + 1),
                isDouble: !1,
                type: F.CIRCLE_QURT,
                width: h,
                height: g,
                rotation: t.rotation === 0 ? Math.PI : Math.PI * 1.5,
            },
        );
    else if (j || k) {
        const c = { x: S, y: A },
            x = {
                x: S,
                y: E(d + 1),
            },
            R = {
                x: m(i + 1),
                y: A,
            },
            O = {
                x: m(i + 1),
                y: E(d + 1),
            };
        if (t.type === F.CROSS || t.type === F.DIAGONAL)
            (t.type === F.CROSS ? [c, x, R, O] : t.rotation === 0 ? [c, O] : [R, x]).forEach(w => {
                I.push({
                    ...t,
                    x: w.x,
                    y: w.y,
                    width: h,
                    height: g,
                    type: F.SQUARE,
                    rotation: 0,
                });
            });
        else {
            const v = [];
            j && !k
                ? v.push(
                      {
                          ...c,
                          type: F.CIRCLE_HALF,
                          rotation: 0,
                          width: h,
                          height: t.height,
                      },
                      {
                          ...R,
                          type: F.CIRCLE_HALF,
                          rotation: Math.PI,
                          width: h,
                          height: t.height,
                      },
                  )
                : !j && k
                ? v.push(
                      {
                          ...c,
                          type: F.CIRCLE_HALF,
                          rotation: 0,
                          width: t.width,
                          height: g,
                      },
                      {
                          ...x,
                          type: F.CIRCLE_HALF,
                          rotation: Math.PI,
                          width: t.width,
                          height: g,
                      },
                  )
                : j &&
                  k &&
                  v.push(
                      {
                          ...R,
                          type: F.CIRCLE_QURT,
                          rotation: 0,
                          width: h,
                          height: g,
                      },
                      {
                          ...c,
                          type: F.CIRCLE_QURT,
                          rotation: Math.PI * 0.5,
                          width: h,
                          height: g,
                      },
                      {
                          ...x,
                          type: F.CIRCLE_QURT,
                          rotation: Math.PI,
                          width: h,
                          height: g,
                      },
                      {
                          ...O,
                          type: F.CIRCLE_QURT,
                          rotation: Math.PI * 1.5,
                          width: h,
                          height: g,
                      },
                  ),
                v.forEach(w => {
                    I.push({
                        ...t,
                        x: w.x,
                        y: w.y,
                        width: w.width,
                        height: w.height,
                        type: w.type,
                        rotation: w.rotation,
                    });
                });
        }
    } else
        I.push({
            ...t,
            x: S,
            y: A,
        });
    return I;
}
const T = {
    width: 1024,
    height: 1024,
    gridSize: 8,
    backgroundColor: '#ffffff',
    foregroundColor: '#000000',
    transparent: !1,
    gap: 10,
    margin: 100,
    whiteSpace: 20,
    input: void 0,
    halfShapesChance: 4,
    nodeShapesChance: 4,
    squareRadius: 12,
    crossRadius: 6,
    circleRadius: 4,
    mode: dt.NONE,
    offsetX: 0,
    offsetY: 0,
};
function Wt({
    width: t = T.width,
    height: o = T.height,
    gridSize: n = T.gridSize,
    backgroundColor: e = T.backgroundColor,
    foregroundColor: a = T.foregroundColor,
    transparent: l = T.transparent,
    gap: f = T.gap,
    margin: y = T.margin,
    whiteSpace: h = T.whiteSpace,
    input: g = T.input,
    halfShapesChance: M = T.halfShapesChance,
    nodeShapesChance: L = T.nodeShapesChance,
    squareRadius: $ = T.squareRadius,
    crossRadius: I = T.crossRadius,
    circleRadius: _ = T.circleRadius,
    mode: C = T.mode,
    offsetX: i = T.offsetX,
    offsetY: d = T.offsetY,
    offsetsRows: u = Array(n).fill(0),
    offsetsCols: s = Array(n).fill(0),
} = {}) {
    C === dt.STRING ? B.change(g) : B.change(), B.reset(), ye(e, a), (C === dt.LETTER || C === dt.STRING) && (n = Gt);
    let r = X.COLOR_20,
        p = [
            {
                position: 0,
                color: r,
                opacity: 1,
            },
            {
                position: 1,
                color: r,
                opacity: 0.2,
            },
        ],
        m = [
            {
                name: 'top-left',
                x1: 0,
                y1: 0,
                x2: 1,
                y2: 1,
                stops: p,
            },
            {
                name: 'top-right',
                x1: 1,
                y1: 0,
                x2: 0,
                y2: 1,
                stops: p,
            },
            {
                name: 'bottom-right',
                x1: 1,
                y1: 1,
                x2: 0,
                y2: 0,
                stops: p,
            },
            {
                name: 'bottom-left',
                x1: 0,
                y1: 1,
                x2: 1,
                y2: 0,
                stops: p,
            },
        ],
        E = C === dt.NONE ? [] : Be(g),
        S = h / 100,
        {
            cells: A,
            nodes: b,
            doubles: D,
            cellWidth: N,
            cellHeight: G,
        } = pe({
            columns: n,
            rows: n,
            rowGap: f,
            columnGap: f,
            width: t,
            height: o,
            margin: { x: y, y },
            excludes: E,
        }),
        U = Math.round((n * n * M) / 144),
        P = [];
    for (let R = 0; R < U; R++)
        if (D.length > 0) {
            const O = B.rangeFloor(0, D.length),
                v = D[O];
            (D = D.filter(w => {
                const q = w.index === v.index,
                    H = w.cells.some(Y => v.cells.includes(Y) || v.neighboursCells.includes(Y));
                return !q && !H;
            })),
                (A = A.filter(w => !v.cells.includes(w.index))),
                (b = b.filter(w => !w.cells.some(q => v.cells.includes(q)))),
                P.push(v);
        }
    let j = [],
        k = n === 3 ? 1 : Math.round((n * n * L) / 144);
    for (let R = 0; R < k; R++)
        if (b.length > 0) {
            const O = B.rangeFloor(0, b.length),
                v = b[O];
            (b = b.filter(w => w.index !== v.index)),
                (b = b.filter(w => !v.neighbours.includes(w.index))),
                (A = A.filter(w => !v.cells.includes(w.index))),
                j.push(v);
        }
    (A = B.shuffle(A)),
        (A.length = Math.floor(A.length * (1 - S))),
        (j = B.shuffle(j)),
        (j.length = Math.floor(j.length * (1 - S))),
        (P = B.shuffle(P)),
        (P.length = Math.floor(P.length * (1 - S)));
    let c = [];
    return (
        l ||
            c.push({
                type: 'rect',
                attributes: {
                    x: 0,
                    y: 0,
                    width: t,
                    height: o,
                    fill: e,
                },
            }),
        [...P, ...A, ...j]
            .reduce(
                (R, O) => [
                    ...R,
                    ...We(O, {
                        offsetX: i,
                        offsetY: d,
                        offsetsCols: s,
                        offsetsRows: u,
                        columns: n,
                        rows: n,
                        margin: { x: y, y },
                        columnGap: f,
                        rowGap: f,
                        cellWidth: N,
                        cellHeight: G,
                    }),
                ],
                [],
            )
            .forEach((R, O) => {
                c.push(
                    Ke({
                        x: R.x,
                        y: R.y,
                        type: R.type,
                        width: R.width,
                        height: R.height,
                        rotation: R.rotation,
                        fill: R.fill,
                        squareRadius: $,
                        circleRadius: _,
                        crossRadius: I,
                    }),
                );
            }),
        { gradients: m, gradientColor: r, width: t, height: o, backgroundColor: e, shapes: c }
    );
}
function tn(t) {
    const { width: o, height: n, gradients: e, shapes: a } = t;
    return `
  <svg width="${o}" height="${n}" viewBox="0 0 ${o} ${n}" xmlns="http://www.w3.org/2000/svg">
      <defs>
          ${e.map(
              f => `
                  <linearGradient id="gradient-${f.name}" x1="${f.x1 * 100}%" y1="${f.y1 * 100}%" x2="${
                  f.x2 * 100
              }%" y2="${f.y2 * 100}%">
                      ${f.stops.map(
                          y => `<stop offset="${y.position}" stop-color="${y.color}" stop-opacity="${y.opacity}" />`,
                      ).join(`
  `)}
                  </linearGradient>
              `,
          ).join(`
  `)}
      </defs>
      ${a.map(f => {
          const { type: y, attributes: h } = f,
              g = Object.keys(h)
                  .map(M => `${M}="${h[M]}"`)
                  .join(' ');
          if (y === 'rect') return `<rect ${g} />`;
          if (y === 'circle') return `<circle ${g} />`;
          if (y === 'path') return `<path ${g} />`;
      }).join(`
  `)}
  </svg>
  `;
}
function nn({
    width: t = T.width,
    height: o = T.height,
    gridSize: n = T.gridSize,
    backgroundColor: e = T.backgroundColor,
    foregroundColor: a = T.foregroundColor,
    transparent: l = T.transparent,
    gap: f = T.gap,
    margin: y = T.margin,
    whiteSpace: h = T.whiteSpace,
    input: g = T.input,
    halfShapesChance: M = T.halfShapesChance,
    nodeShapesChance: L = T.nodeShapesChance,
    squareRadius: $ = T.squareRadius,
    crossRadius: I = T.crossRadius,
    circleRadius: _ = T.circleRadius,
    mode: C = T.mode,
} = {}) {
    const i = Wt({
        width: t,
        height: o,
        gridSize: n,
        backgroundColor: e,
        foregroundColor: a,
        transparent: l,
        gap: f,
        margin: y,
        whiteSpace: h,
        input: g,
        halfShapesChance: M,
        nodeShapesChance: L,
        squareRadius: $,
        crossRadius: I,
        circleRadius: _,
        mode: C,
    });
    return tn(i);
}
function en(t, { mimetype: o = 'png' } = {}) {
    const { width: n, height: e, shapes: a, gradientColor: l } = t,
        f = document.createElement('canvas');
    (f.width = n), (f.height = e);
    const y = f.getContext('2d');
    y.clearRect(0, 0, n, e);
    const h = Ft(l);
    return (
        a.forEach(({ type: g, attributes: M }) => {
            const { fill: L } = M;
            if (L && L.includes('url')) {
                const $ = M['data-x'],
                    I = M['data-y'],
                    _ = M['data-width'],
                    C = M['data-height'],
                    i = /* @__PURE__ */ new Map();
                i.set('url(#gradient-top-left)', [$, I, $ + _, I + C]),
                    i.set('url(#gradient-bottom-left)', [$, I + C, $ + _, I]),
                    i.set('url(#gradient-top-right)', [$ + _, I, $, I + C]),
                    i.set('url(#gradient-bottom-right)', [$ + _, I + C, $, I]);
                const d = Math.round(h[0] * 255),
                    u = Math.round(h[1] * 255),
                    s = Math.round(h[2] * 255),
                    r = y.createLinearGradient(...i.get(L));
                r.addColorStop(0, `rgba(${d}, ${u}, ${s}, 1)`),
                    r.addColorStop(1, `rgba(${d}, ${u},${s}, 0.2)`),
                    (y.fillStyle = r);
            } else y.fillStyle = L;
            if (g === 'circle') {
                const { cx: $, cy: I, r: _ } = M;
                y.beginPath(), y.arc($, I, _, 0, Math.PI * 2), y.fill(), y.closePath();
            } else if (g === 'rect') {
                const { x: $, y: I, width: _, height: C, rx: i = 0 } = M;
                if (i > 0) {
                    try {
                        y.beginPath();
                        y.roundRect($, I, _, C, i);
                        y.closePath();
                        y.fill();
                    } catch (error) {
                        console.error('An error occurred while drawing a rounded rectangle:', error);
                    }
                } else {
                    y.fillRect($, I, _, C);
                }
            } else if (g === 'path') {
                const { d: $ } = M;
                y.fill(new Path2D($));
            }
        }),
        f.toDataURL(`image/${o}`)
    );
}
function on({
    width: t = T.width,
    height: o = T.height,
    gridSize: n = T.gridSize,
    backgroundColor: e = T.backgroundColor,
    foregroundColor: a = T.foregroundColor,
    gap: l = T.gap,
    margin: f = T.margin,
    whiteSpace: y = T.whiteSpace,
    input: h = T.input,
    halfShapesChance: g = T.halfShapesChance,
    nodeShapesChance: M = T.nodeShapesChance,
    squareRadius: L = T.squareRadius,
    crossRadius: $ = T.crossRadius,
    circleRadius: I = T.circleRadius,
    mode: _ = T.mode,
} = {}) {
    const C = Wt({
        width: t,
        height: o,
        gridSize: n,
        backgroundColor: e,
        foregroundColor: a,
        gap: l,
        margin: f,
        whiteSpace: y,
        input: h,
        halfShapesChance: g,
        nodeShapesChance: M,
        squareRadius: L,
        crossRadius: $,
        circleRadius: I,
        mode: _,
    });
    return en(C);
}
export {
    T as defaults,
    Wt as getMindentityData,
    on as getMindentityDataURL,
    en as getMindentityDataURLFromData,
    nn as getMindentitySVG,
    tn as getMindentitySVGFromData,
};
